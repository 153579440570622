import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { InterfaceEventName } from '@uniswap/analytics-events'
import { sendAnalyticsEvent } from 'analytics'
import { useAccountDrawer } from 'components/AccountDrawer'
import { StyledDropDown, StyledSetting } from 'components/Icons/StyledIcon'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useCallback, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled, { useTheme } from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'

import { ReactComponent as AirdropIcon } from '../../assets/imgs/menu/airdrop.svg'
import { ReactComponent as AirdropDarkIcon } from '../../assets/imgs/menu/airdrop_dark.svg'
import { ReactComponent as LeaderBoardIcon } from '../../assets/imgs/menu/leaderBoard.svg'
import { ReactComponent as LeaderBoardDarkIcon } from '../../assets/imgs/menu/leaderBoard_dark.svg'
import { ReactComponent as LockIcon } from '../../assets/imgs/menu/lock.svg'
import { ReactComponent as LockDarkIcon } from '../../assets/imgs/menu/lock_dark.svg'
import { ReactComponent as OrderListIcon } from '../../assets/imgs/menu/orderList.svg'
import { ReactComponent as OrderListDarkIcon } from '../../assets/imgs/menu/orderList_dark.svg'
import { ReactComponent as PrepIcon } from '../../assets/imgs/menu/prep.svg'
import { ReactComponent as PrepDarkIcon } from '../../assets/imgs/menu/prep_dark.svg'
import { ReactComponent as RankingIcon } from '../../assets/imgs/menu/ranking.svg'
import { ReactComponent as RankingDarkIcon } from '../../assets/imgs/menu/ranking_dark.svg'
import { ReactComponent as SwapIcon } from '../../assets/imgs/menu/swap.svg'
import { ReactComponent as SwapDarkIcon } from '../../assets/imgs/menu/swap_dark.svg'
import { ReactComponent as VoteIcon } from '../../assets/imgs/menu/vote.svg'
import { ReactComponent as VoteDarkIcon } from '../../assets/imgs/menu/vote_dark.svg'
import { HoverBar } from './HoverBar'
import { NavDropdown } from './NavDropdown'

const StyledNavBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1;
  .navItem {
    padding: 8px 16px;
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    &:hover {
      background: ${({ theme }) => theme.menuHover};
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.xl}px) {
    gap: 12px;
    justify-content: space-between;
    .navItem {
      padding: 8px 10px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    width: 100%;
    gap: 0;
    .navItem {
      padding: 8px 4px;
      flex: 1;
      &:hover {
        background: transparent;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    .navItem {
      width: fit-content;
    }
  }
`

const StyledMenuList = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 200px;
  padding: 8px 16px;
  gap: 12px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    flex-direction: row;
    gap: 4px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    gap: 2px;
  }
`

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`

const StyledMenuItem = styled(StyledNavLink)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  border-radius: 4px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    flex-direction: column;
    flex: 1;
  }
`

const StyledDownMenuItem = styled(StyledMenuItem)`
  min-height: 36px;
  &:hover {
    background: ${({ theme }) => theme.menuHover};
  }
`

export default function NavMenuTabs() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const isSmallScreen2 = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)

  const [isTradeOpen, setIsTradeOpen] = useState(false)
  const [isLiquidityOpen, setIsLiquidityOpen] = useState(false)
  const [isGovernanceOpen, setIsGovernanceOpen] = useState(false)
  const [isAirdropOpen, setIsAirdropOpen] = useState(false)
  const tradeRef = useRef<HTMLDivElement>(null)
  const liquidityRef = useRef<HTMLDivElement>(null)
  const governanceRef = useRef<HTMLDivElement>(null)
  const airdropRef = useRef<HTMLDivElement>(null)

  const toggleTradeOpen = () => {
    setIsTradeOpen(!isTradeOpen)
  }

  const toggleLiquidityOpen = () => {
    setIsLiquidityOpen(!isLiquidityOpen)
  }

  const toggleGovernanceOpen = () => {
    setIsGovernanceOpen(!isGovernanceOpen)
  }

  const toggleAirdropOpen = () => {
    setIsAirdropOpen(!isAirdropOpen)
  }

  useOnClickOutside(tradeRef, isTradeOpen ? toggleTradeOpen : undefined)
  useOnClickOutside(liquidityRef, isLiquidityOpen ? toggleLiquidityOpen : undefined)
  useOnClickOutside(governanceRef, isGovernanceOpen ? toggleGovernanceOpen : undefined)

  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()
  const handleWalletDropdownClick = useCallback(() => {
    sendAnalyticsEvent(InterfaceEventName.ACCOUNT_DROPDOWN_BUTTON_CLICKED)
    toggleAccountDrawer()
  }, [toggleAccountDrawer])

  const theme = useTheme()

  const tradeNavList = [
    {
      title: 'Swap',
      href: '/swap',
      icon: theme.darkMode ? <SwapDarkIcon /> : <SwapIcon />,
    },
    {
      title: 'Perp',
      href: '/perp',
      icon: theme.darkMode ? <PrepDarkIcon /> : <PrepIcon />,
    },
    {
      title: 'LeaderBoard',
      href: '/leaderBoard',
      icon: theme.darkMode ? <LeaderBoardDarkIcon /> : <LeaderBoardIcon />,
    },
    {
      title: 'OrderList',
      href: '/orderList',
      icon: theme.darkMode ? <OrderListDarkIcon /> : <OrderListIcon />,
    },
  ]

  const governanceNavList = [
    {
      title: 'Lock',
      href: '/lock',
      icon: theme.darkMode ? <LockDarkIcon /> : <LockIcon />,
    },
    {
      title: 'Vote',
      href: '/vote',
      icon: theme.darkMode ? <VoteDarkIcon /> : <VoteIcon />,
    },
  ]

  const airdropNavList = [
    {
      title: 'Airdrop',
      href: '/airdrop',
      icon: theme.darkMode ? <AirdropDarkIcon /> : <AirdropIcon />,
    },
    {
      title: 'Ranking',
      href: '/ranking',
      icon: theme.darkMode ? <RankingDarkIcon /> : <RankingIcon />,
    },
  ]

  return (
    <Box display="flex" alignItems="center" width="100%" gap="4px">
      <StyledNavBox>
        <div className="navItem" ref={tradeRef}>
          <HoverBar
            textProps={
              <Box display="flex" justifyContent="center" alignItems="center" gap="4px">
                <ThemedText.TextPrimary fontSize={isSmallScreen2 ? 12 : isSmallScreen ? 14 : 16}>
                  <Trans>Trade</Trans>
                </ThemedText.TextPrimary>
                <StyledDropDown />
              </Box>
            }
            navDown={
              <NavDropdown>
                <StyledMenuList onMouseLeave={toggleTradeOpen}>
                  {tradeNavList.map(({ title, href, icon }) => (
                    <StyledDownMenuItem to={href} onClick={toggleTradeOpen} key={title}>
                      {icon}
                      <ThemedText.TextPrimary fontSize={isSmallScreen ? 14 : 16}>
                        <Trans>{title}</Trans>
                      </ThemedText.TextPrimary>
                    </StyledDownMenuItem>
                  ))}
                </StyledMenuList>
              </NavDropdown>
            }
          />
        </div>
        {isSmallScreen && (
          <Box p="0 4px">
            <ThemedText.TextPrimary color="swapSectionBorder">|</ThemedText.TextPrimary>
          </Box>
        )}
        <Box display="flex" justifyContent="center" className="navItem">
          <StyledNavLink to="/pools">
            <ThemedText.TextPrimary fontSize={isSmallScreen2 ? 12 : isSmallScreen ? 14 : 16}>
              <Trans>Pools</Trans>
            </ThemedText.TextPrimary>
          </StyledNavLink>
        </Box>
        {isSmallScreen && (
          <Box p="0 4px">
            <ThemedText.TextPrimary color="swapSectionBorder">|</ThemedText.TextPrimary>
          </Box>
        )}
        <div className="navItem" ref={governanceRef}>
          <HoverBar
            textProps={
              <Box display="flex" justifyContent="center" alignItems="center" gap="4px">
                <ThemedText.TextPrimary fontSize={isSmallScreen2 ? 12 : isSmallScreen ? 14 : 16}>
                  <Trans>Governance</Trans>
                </ThemedText.TextPrimary>
                <StyledDropDown />
              </Box>
            }
            navDown={
              <NavDropdown>
                <StyledMenuList onMouseLeave={toggleGovernanceOpen}>
                  {governanceNavList.map(({ title, href, icon }) => (
                    <StyledDownMenuItem to={href} onClick={toggleGovernanceOpen} key={title}>
                      {icon}
                      <ThemedText.TextPrimary fontSize={isSmallScreen ? 14 : 16}>
                        <Trans>{title}</Trans>
                      </ThemedText.TextPrimary>
                    </StyledDownMenuItem>
                  ))}
                </StyledMenuList>
              </NavDropdown>
            }
          />
        </div>
        {isSmallScreen && (
          <Box p="0 4px">
            <ThemedText.TextPrimary color="swapSectionBorder">|</ThemedText.TextPrimary>
          </Box>
        )}
        <div className="navItem" ref={airdropRef}>
          <HoverBar
            textProps={
              <Box display="flex" justifyContent="center" alignItems="center" gap="4px">
                <ThemedText.TextPrimary fontSize={isSmallScreen2 ? 12 : isSmallScreen ? 14 : 16}>
                  <Trans>Airdrop</Trans>
                </ThemedText.TextPrimary>
                <StyledDropDown />
              </Box>
            }
            navDown={
              <NavDropdown>
                <StyledMenuList onMouseLeave={toggleAirdropOpen}>
                  {airdropNavList.map(({ title, href, icon }) => (
                    <StyledDownMenuItem to={href} onClick={toggleAirdropOpen} key={title}>
                      {icon}
                      <ThemedText.TextPrimary fontSize={isSmallScreen ? 14 : 16}>
                        <Trans>{title}</Trans>
                      </ThemedText.TextPrimary>
                    </StyledDownMenuItem>
                  ))}
                </StyledMenuList>
              </NavDropdown>
            }
          />
        </div>
      </StyledNavBox>
      {isSmallScreen && (
        <Box display="flex" alignItems="center" p="0 4px" data-testid="settings" onClick={handleWalletDropdownClick}>
          <StyledSetting />
        </Box>
      )}
    </Box>
  )
}
